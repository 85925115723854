import React from "react";
import "../sass/SalePreviewSummary.scss";

function SalePreviewSummary(props) {
  let tiemposQty = props.tiemposQty > 0 ? props.tiemposQty : 0;
  let costoDelSorteo = props.costoDelSorteo > 0 ? props.costoDelSorteo : 0;
  let totalSale = props.totalSale > 0 ? props.totalSale : 0;

  return (
    <div className="row SaleSummary">
      <div className="col-4 available text-center">
        <p className="available__qty">{tiemposQty}</p>
        <p className="available__label">Total Tiempos</p>
      </div>
      <div className="col-4 tiempos text-center">
        <p className="tiempos__qty">${costoDelSorteo.toFixed(2)}</p>
        <p className="tiempos__label">Precio x tiempo</p>
      </div>
      <div
        className={`col-4 total text-center ${
          props.highlightTotal === true && "highlight"
        }`}
      >
        <p className="total__qty">${Number(totalSale).toFixed(2)}</p>
        <p className="total__label">Total</p>
      </div>
    </div>
  );
}
export { SalePreviewSummary };
