import React from "react";
import { Link } from "react-router-dom";
import "../sass/SideHeader.scss";
import { BsList } from "react-icons/bs";

function SideHeader(props) {
  let destination;
  if (props.destination == null) {
    destination = "/sorteo";
  } else if (props.destination === "-") {
    destination = null;
  } else {
    destination = props.destination;
  }

  return (
    <header className="sideHeader container pt-1 sticky-top">
      <div className="row mb-2">
        <div className="col-12 sideHeader-content pt-2 pb-2">
          <nav className="sideHeader-content__navbar">
            {destination === null ? (
              <React.Fragment>
                <span>
                  <i>
                    <BsList></BsList>
                  </i>
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Link to={destination}>
                  <i>
                    <BsList></BsList>
                  </i>
                </Link>
              </React.Fragment>
            )}
          </nav>
          <h2 className="col sideHeader-content__title">{props.title}</h2>
        </div>
      </div>
    </header>
  );
}

export { SideHeader };
