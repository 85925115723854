import React from "react";

function SaleSuccessTicketInfo(props) {
  console.log(props.data, "inside");
  const ticketOutput = {
    client: props.data === 0 ? "--" : props.data[0].acq.client_name,
    doc: props.data === 0 ? "--" : props.data[0].doc,
    seller: props.data === 0 ? "--" : props.data[0].userInfo.name,
    sellerPhone: props.data === 0 ? "--" : props.data[0].userInfo.phone,
    costoSorteo: props.data === 0 ? 0 : props.costoDelSorteo,
    ticket: props.data === 0 ? "--" : props.data[0].ref,
  };

  return (
    <React.Fragment>
      <div className="row ticket-data__info">
        <div className="col">
          <p className="line">
            Cliente: <span className="data">{ticketOutput.client}</span>
          </p>
          <p className="line">
            Fecha: <span className="data">{ticketOutput.doc}</span>
          </p>
          <p className="line sellerName">
            Vendedor: <span className="data">{ticketOutput.seller}</span>
          </p>
          <p className="line">
            MODO: <span className="data">S TIC </span>
          </p>
          <p className="line">
            Ticker #: <span className="data">{ticketOutput.ticket}</span>
          </p>
          <p className="line">
            Unit:{" "}
            <span className="data">{`$${Number(
              ticketOutput.costoSorteo
            ).toFixed(2)}`}</span>
          </p>
          <p className="line">
            Tel: <span className="data">{ticketOutput.sellerPhone}</span>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
export { SaleSuccessTicketInfo };
