import React from "react";
import "../sass/SorteoDashboard.scss";
import { format } from "date-fns";
import { FaHandHoldingUsd } from "react-icons/fa";
import { GiSwapBag } from "react-icons/gi";
import { BsFillBarChartFill, BsArrowReturnRight } from "react-icons/bs";
import { Link } from "react-router-dom";

function getJsDateFormat(date) {
  return new Date(date.concat("Z").replace(/\s/, "T"));
}

function SorteoDashboard(props) {
  const sorteoData = props.data.records[0];

  let country = sorteoData.source === 1 ? "Panamá" : "Costa Rica";

  // Format variables
  let validToDate = format(getJsDateFormat(sorteoData.date), "dd/MM/yyyy");
  let time = getJsDateFormat(sorteoData.date);
  let validToTime = time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  //Premios
  console.log(sorteoData, "[SorteoDashboard] :: SorteoData");
  const premiadosAll = sorteoData.tiempos.tiemposPremiados;
  let premiadosAmound =
    Number(premiadosAll.primer_premio.amount) +
    Number(premiadosAll.segundo_premio.amount) +
    Number(premiadosAll.tercer_premio.amount);
  console.log(premiadosAll, "Premiados");

  //Format Sales Metrics
  let totalTiempos = sorteoData.tiempos.tiemposSold;
  let totalSales = Number(totalTiempos) * Number(sorteoData.costo);
  let comision = ((totalSales * Number(sorteoData.comision)) / 100).toFixed(2);
  let ventaUtil = totalSales - comision - premiadosAmound;

  return (
    <React.Fragment>
      <div className="row sorteo-review mx-0 mb-3 rounded">
        <div className="col-12 sorteo__dashboard">
          <div className="row sorteo-info">
            <div className="col-12 d-flex justify-content-between pt-2 pb-2">
              <p className="country">{country}</p>
              <p className="sortid">{`${sorteoData.sourceLabel}${sorteoData.ref}`}</p>
            </div>
            <div className="col">
              <hr className="m-0" />
            </div>
          </div>
          <div className="row sorteo-statistics mt-3">
            <div className="col sorteo-statistics__sales">
              <div>
                <label htmlFor="total__sale">Total:</label>
                <p className="total__sale" id="total__sale">
                  {totalSales.toFixed(2)}
                </p>
              </div>
              <div>
                <label htmlFor="total__comission">
                  Comisión {sorteoData.comision}%
                </label>
                <p className="total__comission" id="total__comission">
                  <i>
                    <BsArrowReturnRight></BsArrowReturnRight>
                    {comision}
                  </i>
                </p>
              </div>
            </div>
            <div className="col sorteo-statistics__others text-center">
              <Link to={"/transactions"} className="d-inline-flex">
                <i>
                  <BsFillBarChartFill></BsFillBarChartFill>
                </i>
              </Link>
            </div>
          </div>
          <div className="row sorteo-validTo mt-2">
            <div className="col d-flex justify-content-between">
              <p className="validDate mb-1">{validToDate}</p>
              <p className="validTime mb-1">{validToTime}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row metrics mx-0 gap-2 mb-3">
        <div className="col metrics-tiempos rounded pt-3 pb-3">
          <GiSwapBag></GiSwapBag>
          <p className="metrics-tiempos__txt">Tiempos: {totalTiempos}</p>
          <p className="metrics-tiempos__amount">{ventaUtil.toFixed(2)}</p>
        </div>
        <div className="col metrics-prize rounded pt-3 pb-3">
          <FaHandHoldingUsd></FaHandHoldingUsd>
          <p className="metrics-prize__txt">Premios </p>
          <p className="metrics-prize__amount">${premiadosAmound.toFixed(2)}</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export { SorteoDashboard };
