import React from "react";
import "../sass/ProfileHeader.scss";
import { FaRegUser, FaRegBell, FaListUl, FaFileAlt } from "react-icons/fa";
import { RiCloseFill } from "react-icons/ri";
import { GoSignOut } from "react-icons/go";
import { Link } from "react-router-dom";

function ProfileHeader(props) {
  const userData = props.data[0];

  const sideMenuAction = (e) => {
    const sideNav = document.getElementById("mySideNav");
    switch (e) {
      case "close":
        console.log("Closing...");
        sideNav.style.width = "0";
        sideNav.style.left = "-100%";
        sideNav.classList.remove("open");
        sideNav.classList.add("close");
        break;
      default:
        console.log("Opening...");
        sideNav.style.width = "80%";
        sideNav.style.left = "0";
        sideNav.classList.remove("close");
        sideNav.classList.add("open");
        break;
    }
  };

  return (
    <header className="container ProfileHeader">
      <div className="row align-items-center mt-2 mb-3">
        <div className="col-9 profile d-flex gap-2 align-items-center">
          <button
            type="button"
            className="profile__icon"
            onClick={(e) => sideMenuAction()}
          >
            <FaRegUser></FaRegUser>
          </button>
          <div className="profile-info">
            <p className="profile-info__name">{`${userData.name} ${
              !userData.lastname ? "" : userData.lastname
            }`}</p>
            <p className="profile-info__phone">{`${userData.phone}`}</p>
          </div>
        </div>
        <div className="col notifications justify-content-end text-end">
          <a className="notifications__icon" href="#">
            <FaRegBell></FaRegBell>
          </a>
        </div>
      </div>
      <div id="mySideNav" className="sideNav container close">
        <div className="row header">
          <div className="col">
            <p className="header__name">Hola! {userData.name}</p>
          </div>
          <div className="col d-flex justify-content-end header__closeIcon">
            <button
              type="button"
              className="btn"
              onClick={(e) => sideMenuAction("close")}
            >
              <RiCloseFill></RiCloseFill>
            </button>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col">
            <nav className="navbar">
              <ul className="navbar-nav">
                <Link to={"/list"}>
                  <li className="nav-item">
                    <FaListUl></FaListUl>
                    <span>Lista de Sorteos</span>
                  </li>
                </Link>
                <Link to={"/report"}>
                  <li className="nav-item">
                    <FaFileAlt></FaFileAlt>
                    <span>Reporte</span>
                  </li>
                </Link>
                <Link to={"/logout"} className={"signout"}>
                  <li className="nav-item">
                    <GoSignOut></GoSignOut>
                    <span>Cerrar sesión</span>
                  </li>
                </Link>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export { ProfileHeader };
