import React from "react";

function TicketPrint(props) {
  const printTicketData = {
    client: props.data === 0 ? "--" : props.data[0].acq.client_name,
    doc: props.data === 0 ? "--" : props.data[0].doc,
    seller: props.data === 0 ? "--" : props.data[0].userInfo.name,
    sellerPhone: props.data === 0 ? "--" : props.data[0].userInfo.phone,
    costoSorteo: props.data === 0 ? 0 : props.costoDelSorteo,
    ticket: props.data === 0 ? "--" : props.data[0].ref,
  };

  let tiemposQty = 0;
  props.data[0].acquisition.map((item) => (tiemposQty += Number(item.tiempos)));

  return (
    <React.Fragment>
      <div className="row">
        <h2>Ticket AAAAGRUP #{printTicketData.ticket}</h2>
        <p className="print-line mb-1">{`Cliente: ${printTicketData.client}`}</p>
        <p className="print-line mb-1">{`Fecha: ${printTicketData.doc}`}</p>
        <p className="print-line mb-1">{`Vendedor: ${printTicketData.seller}`}</p>
        <p className="print-line mb-1">{`Teléfono: ${printTicketData.sellerPhone}`}</p>
        <p className="print-line mb-1">{`Modo: ${printTicketData.mod}`}</p>
        <p className="print-line mb-1">{`Ticket: ${printTicketData.ticket}`}</p>
        <p className="print-line mb-1">{`$${Number(
          printTicketData.costoSorteo
        ).toFixed(2)}`}</p>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="mb-0">Tiempos adquiridos:</p>
        </div>
        <div className="mx-2 mt-0 row acquisitions">
          <div className="col">
            {props.data[0].acquisition.map((item, index) => (
              <span
                key={index}
                className="px-2 item"
              >{`${item.tiempos}t - ${item.numeros}a`}</span>
            ))}
          </div>
        </div>
      </div>
      <div className="row total">
        <div className="col-12">
          <p className="print-line mt-4 mb-0">{`Total de tiempos: ${tiemposQty}`}</p>
          <p className="print-line mb-0">{`Precio por tiempos: ${Number(
            printTicketData.costoSorteo
          )}`}</p>
          <p className="print-line mb-1">{`Total: $${(
            tiemposQty * Number(printTicketData.costoSorteo)
          ).toFixed(2)}`}</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export { TicketPrint };
