import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../sass/SaleSuccess.scss";
import { SalePreviewSummary } from "../components/SalePreviewSummary";
import { SaleSuccessNumberSold } from "../components/SaleSuccessNumberSold";
import { SideHeader } from "../components/SideHeader";
import { SaleSuccessTicketInfo } from "../components/SaleSucessTicketInfo";
import { TicketPrint } from "../components/TicketPrint";
import { LoadingTag } from "../components/LoadingTag";

function SaleSuccess() {
  // Get session data
  const sessionData = JSON.parse(sessionStorage.getItem("a4g"));

  //Get Data on localStore
  const sorteoData = JSON.parse(localStorage.getItem("a4g"));
  const ticketLocalData = JSON.parse(localStorage.getItem("a4g_ticket"));

  const [ticketData, setTicketData] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Tiempos Total
  const [tiemposTotal, setTiemposTotal] = useState(0);

  // Navigation capabilities
  let navigate = useNavigate();

  const apiEndpoint = `https://api.aaaagrup.com`;

  useEffect(() => {
    setLoading(true);
    fetch(
      `${apiEndpoint}?resource=tiempos&rid=${ticketLocalData.last_insertId}&filters=true&where=tid`
    )
      .then((response) => response.json())
      .then((data) => {
        setTicketData(data);

        let qty = 0;
        data[0].acquisition.forEach((element) => {
          qty += Number(element.tiempos);
        });

        setTiemposTotal(qty);
      })
      .then(() => setLoading(false))
      .catch(setError);
  }, []);

  if (loading) return <LoadingTag></LoadingTag>;
  if (error) return <pre>{JSON.stringify(error)}</pre>;
  if (!ticketData) return null;

  const actionsBtn = (e) => {
    switch (e) {
      case "delete":
        if (window.confirm("¿Seguro quieres borrar esta venta?")) {
          //Ask user to enter its username to confirm his decission
          let userInput = prompt("Ingresa tu usuario");
          if (userInput === sessionData.username) {
            console.log(`Ok borraremos por ${sessionData.username}`);

            fetch(
              `${apiEndpoint}?resource=tiempos&rid=${ticketLocalData.last_insertId}`,
              {
                method: "DELETE",
                headers: {
                  "Content-type": "application/json",
                },
              }
            )
              .then((response) => response.json())
              .then(() => {
                navigate("/sorteo");
              });
          } else {
            alert("Información equivocada");
          }
        }
        break;

      case "print":
        let divContents = document.getElementById("print_div").innerHTML;
        let a = window.open("", "", "height=auto, width=500");
        a.document.write(
          '<html><head><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"></head>'
        );
        a.document.write('<body> <main class="container-fluid sale-success"');
        a.document.write(divContents);
        a.document.write("</main></body></html>");
        a.document.close();
        a.print();

        break;

      case "close":
        //Clean up the mess
        localStorage.removeItem("a4g_ticket");

        // Goint to mainScreen
        navigate("/sorteo");
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <SideHeader
        title={`Ticket #${ticketLocalData.ticket}`}
        destination={"-"}
      ></SideHeader>
      <main className="container sale-success" id="ticket_invoice">
        <div className="row ticket pt-2 pb-2 mb-2">
          <div className="col-12">
            <h3 className="ticket__title">AAAAGRUP</h3>
          </div>
          <div className="col ticket-data">
            <SaleSuccessTicketInfo
              data={ticketData}
              costoDelSorteo={sorteoData.costo}
            ></SaleSuccessTicketInfo>
          </div>
        </div>
        <div className="row ticket-data__saleNumbers">
          <p className="saleNumbers-title mb-1">Tiempos adquiridos: </p>
          {ticketData[0].acquisition.map((item, index) => (
            <SaleSuccessNumberSold
              key={index}
              tiemposSold={item.tiempos}
              numberSold={item.number}
            ></SaleSuccessNumberSold>
          ))}
        </div>
        <div className="row finish-bar pt-3 pb-3 px-2 fixed-bottom">
          <div className="col-12 mb-3">
            <SalePreviewSummary
              highlightTotal={true}
              costoDelSorteo={sorteoData.costo}
              tiemposQty={tiemposTotal}
              totalSale={tiemposTotal * Number(sorteoData.costo)}
            ></SalePreviewSummary>
            <div className="row success-actions">
              <div className="col-12">
                <hr />
              </div>
              <div className="col-12 success-actions__btnList">
                <button
                  className="delete"
                  onClick={(e) => actionsBtn(`delete`)}
                  name="delete_btn"
                  id="delete_btn"
                >
                  <i className="bi bi-trash"></i>
                  <span>Eliminar</span>
                </button>
                <button
                  className="print"
                  onClick={(e) => actionsBtn(`print`)}
                  name="print_btn"
                  id="print_btn"
                >
                  <i className="bi bi-printer"></i>
                  <span>Imprimir</span>
                </button>
                <button
                  className="share"
                  onClick={(e) => actionsBtn(`share`)}
                  name="share_btn"
                  id="share_btn"
                >
                  <i className="bi bi-share"></i>
                  <span>Enviar</span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 finish-bar__closeBtn d-grid">
            <button
              className="btn btn-secondary"
              type="button"
              name="close_btn"
              id="close_btn"
              onClick={(e) => actionsBtn(`close`)}
            >
              Cerrar
            </button>
          </div>
        </div>
      </main>
      <div id="print_div" style={{ display: "none" }}>
        <TicketPrint
          data={ticketData}
          costoDelSorteo={sorteoData.costo}
        ></TicketPrint>
      </div>
    </React.Fragment>
  );
}

export { SaleSuccess };
