import React, { useState, useEffect } from "react";
import { ProfileHeader } from "../components/ProfileHeader";
import { SorteoDashboard } from "../components/SorteoDashboard";
import { SorteoTransactionItem } from "../components/SorteoTransactionItem";
import { FooterNav } from "../components/FooterNav";
import { LoadingTag } from "../components/LoadingTag";
import { Link } from "react-router-dom";

function SingleSorteo() {
  // const apiEndpoint = `https://api.aaaagrup.com`;
  const apiEndpoint = `http://localhost:8080`;

  const sorteoStore = JSON.parse(localStorage.getItem("a4g"));

  const userSession = sessionStorage.getItem("a4g");
  const sessionData = JSON.parse(userSession);
  const userCredentials = {
    token: sessionData.token,
    username: sessionData.username,
    uid: sessionData.uid,
  };

  // console.log(sorteoStore.sorteo);
  // console.log(userCredentials);

  //Getting the information from the api
  const [userGotData, setUserData] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [sorteoGotData, setSorteoData] = useState(false);
  const [sorteoInfo, setSorteoInfo] = useState(null);

  // Sales Data
  const [gotSales, setSales] = useState(false);
  const [salesData, setSalesData] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    //Get User info
    fetch(`${apiEndpoint}?resource=users&rid=${userCredentials.uid}`)
      .then((response) => response.json())
      .then((data) => setUserInfo(data))
      .then(() => setUserData(true));

    //Get sorteo info
    fetch(
      `${apiEndpoint}?resource=sorteos&rid=${sorteoStore.sorteo}&filters=true&userId=${userCredentials.uid}`
    )
      .then((response) => response.json())
      .then((data) => setSorteoInfo(data))
      .then(() => setSorteoData(true));

    // Get sales data
    fetch(
      `${apiEndpoint}?resource=tiempos&rid=${sorteoStore.sorteo}&filters=true&where=sid&uid=${userCredentials.uid}`
    )
      .then((response) => response.json())
      .then((data) => setSalesData(data))
      .then(() => setSales(true))
      .then(() => setLoading(false))
      .catch(setError);
  }, []);

  if (loading) return <LoadingTag></LoadingTag>;
  if (error) return <pre>{JSON.stringify(error)}</pre>;
  if (!sorteoGotData) return null;

  return (
    <React.Fragment>
      {userGotData && <ProfileHeader data={userInfo}></ProfileHeader>}
      {sorteoGotData && (
        <main className="container">
          <SorteoDashboard data={sorteoInfo}></SorteoDashboard>
          <div className="row transactions mb-4">
            <div className="col-12 transactions-header d-flex justify-content-between">
              <p className="title">Transacciones </p>
              <p className="action">
                <Link to={"/transactions"}>Ver todas</Link>
              </p>
            </div>
            <div className="col-12 transactions-preview">
              <div className="row mb-5">
                <SorteoTransactionItem
                  sales={salesData}
                  premiados={sorteoInfo}
                  maxItems={5}
                ></SorteoTransactionItem>
              </div>
            </div>
          </div>
        </main>
      )}
      <FooterNav></FooterNav>
    </React.Fragment>
  );
}

export { SingleSorteo };
