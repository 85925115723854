import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../sass/Sale.scss";
import { SideHeader } from "../components/SideHeader";
import { BsFillXCircleFill, BsChevronDoubleRight } from "react-icons/bs";

const keypad = [1, 2, 3, 4, 5, 6, 7, 8, 9, "c", 0, "ok"];

/**
 * Count how many "tiempos" exists inside the given object
 * @param {Object} inputData
 * @returns Number
 */
function countHowManyTimes(inputData) {
  let qty = Number(0);
  inputData.map((item) => {
    qty += Number(item.tiempos);
  });

  return Number(qty);
}

/**
 * Hanldes the store of the values inside localstorage
 * @param {*} inputValues
 * @returns Number
 */
function storeValue(inputValues) {
  let oldValues;
  let newValues;
  let qtyTiempos;
  if (localStorage.getItem("a4g_sale") === null) {
    // If the storage is not set
    console.log(inputValues);

    localStorage.setItem("a4g_sale", JSON.stringify([inputValues]));
    qtyTiempos = inputValues.tiempos;
    return qtyTiempos;
  } else {
    // If the storage is already set
    oldValues = JSON.parse(localStorage.getItem("a4g_sale"));

    newValues = [...oldValues, inputValues];
    console.log(oldValues, "Old Values");
    console.log(newValues, "New Values");

    localStorage.setItem("a4g_sale", JSON.stringify(newValues));
    return (qtyTiempos = countHowManyTimes(newValues));
  }
}

/**
 * Main Component
 * @returns
 */
function Sale() {
  const tiempos = useRef();
  const numeros = useRef();
  const [what, setWhat] = useState("tiempos");

  //Check if we got previously saved numbers in localstorage
  let qty = 0;
  if (localStorage.getItem("a4g_sale") !== null) {
    const saleDataExisting = JSON.parse(localStorage.getItem("a4g_sale"));
    qty = countHowManyTimes(saleDataExisting);
    console.log(qty, "prexisting QTY");
  }
  const [totalNumberQty, setTotalNumberQty] = useState(qty);

  let navigate = useNavigate();

  //Get Costo of this sorteo
  const sorteoData = JSON.parse(localStorage.getItem("a4g"));

  const digitKey = (e) => {
    console.log(`Init at: ${what} || ${e.target.dataset.value}`);

    let dataStored;

    switch (e.target.dataset.value) {
      case "ok":
        console.log(`Hit "OK" btn: ${e.target.dataset.value}`);
        what === "tiempos" && setWhat("numeros");
        what === "numeros" && setWhat("tiempos");

        // Check if we got both values
        if (tiempos.current.value > 1 && numeros.current.value > 1) {
          dataStored = storeValue({
            tiempos: Number(tiempos.current.value),
            number: Number(numeros.current.value),
          });
          setTotalNumberQty(dataStored);
          console.log(`${tiempos.current.value}:${numeros.current.value}`);
          //Clean up the registry
          tiempos.current.value = "";
          numeros.current.value = "";
          setWhat("tiempos");
        }
        break;
      case "c":
        console.log(`Hit "C" btn: ${e.target.dataset.value}`);
        tiempos.current.value = "";
        numeros.current.value = "";
        setWhat("tiempos");
        break;
      default:
        console.log(`Hit btn: ${e.target.dataset.value}`);
        if (what === "tiempos") {
          tiempos.current.value += e.target.dataset.value;
        } else {
          numeros.current.value += e.target.dataset.value;
        }
        break;
    }
  };

  return (
    <React.Fragment>
      <SideHeader title="Nueva venta"></SideHeader>
      <main className="container Sale">
        <div className="row sale-screen justify-content-center pt-4 pb-4">
          <div className="col-11 sale-screen__display">
            <div className="tiempos text-center">
              <input
                ref={tiempos}
                type="number"
                min="0"
                step="1"
                max="999"
                className="tiempos__qty"
                id="tiempos__qty"
                placeholder="000"
                disabled
                required
              />
              <label
                htmlFor="tiempos__qty"
                className={`tiempos__label ${what === "tiempos" && "active"}`}
              >
                Tiempos
              </label>
            </div>
            <div className="numero text-center">
              <input
                ref={numeros}
                type="number"
                min="0"
                max="99"
                step="1"
                placeholder="00"
                className="numero__qty"
                id="numero__qty"
                disabled
                required
              />
              <label
                htmlFor="numero__qty"
                className={`numero__label ${what === "numeros" && "active"}`}
              >
                Número
              </label>
            </div>
          </div>
        </div>
        <div className="row sale-total justify-content-center pt-3 pb-3 mb-2">
          <div className="col-4 available text-center">
            <p className="available__qty">-----</p>
            <p className="available__label">Disponible</p>
          </div>
          <div className="col-4 tiempos text-center">
            <p className="tiempos__qty">{totalNumberQty}</p>
            <p className="tiempos__label">Tiempos</p>
          </div>
          <div className="col-4 total text-center">
            <p className="total__qty">
              ${(totalNumberQty * sorteoData.costo).toFixed(2)}
            </p>
            <p className="total__label">Total</p>
          </div>
        </div>
        <div className="row keypad">
          <div className="col digits gap-2">
            {keypad.map((item) => (
              <button
                key={item}
                id={`key_${item}`}
                data-value={item}
                onClick={digitKey}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
        <div className="row actions mt-2">
          <div className="col actions__buttons-list">
            <button
              className="cancel"
              type="reset"
              onClick={() => {
                navigate("/sorteo");
              }}
            >
              <i>
                <BsFillXCircleFill></BsFillXCircleFill>
              </i>
            </button>
            <button
              className="submit"
              type="submit"
              onClick={() => {
                navigate(`/preview`);
              }}
            >
              <i>
                <BsChevronDoubleRight></BsChevronDoubleRight>
              </i>
            </button>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export { Sale };
