import React, { useState, useEffect } from "react";
import { SorteoPicker } from "../components/SorteoPicker";
import { SideHeader } from "../components/SideHeader";
import { LoadingTag } from "../components/LoadingTag";

function SorteosList(props) {
  const [sortData, setSortData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // const apiEndpoint = `https://api.aaaagrup.com`;
  const apiEndpoint = `http://localhost:8080`;

  //Request the data on loading this page
  useEffect(() => {
    setLoading(true);
    fetch(`${apiEndpoint}?resource=sorteos&limit=365`)
      .then((response) => response.json())
      .then((data) => setSortData(data))
      .then(() => setLoading(false))
      .catch(setError);
  }, []);

  if (loading)
    return (
      <React.Fragment>
        <LoadingTag></LoadingTag>
      </React.Fragment>
    );
  if (error) return <pre>{JSON.stringify(error)}</pre>;
  if (!sortData) return null;

  return (
    <React.Fragment>
      <SideHeader title="Selecciona un sorteo" destination={"-"}></SideHeader>
      <div className="container sorteos-list">
        {sortData.records.map((item) => (
          <SorteoPicker
            key={item.sortid}
            sid={item.sortid}
            source={item.source}
            validTo={item.date}
            sourceLabel={item.sourceLabel}
            refNumber={item.ref}
            itemActive={item.status}
            sorteoCosto={item.costo}
          ></SorteoPicker>
        ))}
      </div>
    </React.Fragment>
  );
}

export { SorteosList };
