import React from "react";
import { BsTrash } from "react-icons/bs";

function SalePreviewItemSold(props) {
  let eachLineAmount = Number(0);
  eachLineAmount = props.tiempos * props.costo;

  let outputNumero = props.numero;
  outputNumero = `${outputNumero}`.length < 2 && `0${props.numero}`;

  return (
    <div
      id={`item_sale_${props.itemId}`}
      className="row item-sold"
      data-itemid={props.itemId}
    >
      <div className="col-8 item-sold__number">
        <div className="tiempos">
          {props.tiempos}
          <span className="superScript">t</span>
        </div>
        <div className="item-number">
          {outputNumero}
          <span className="superScript">a</span>
        </div>
      </div>
      <div className="col-2 item-sold__amount">
        <span>${eachLineAmount.toFixed(2)}</span>
      </div>
      <button
        className="col-2 item-sold__delete"
        name={`delete_item_sale_${props.itemId}`}
        data-itemid={props.itemId}
        onClick={props.onClick}
      >
        <BsTrash></BsTrash>
      </button>
    </div>
  );
}

export { SalePreviewItemSold };
