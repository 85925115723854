import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../sass/Login.scss";
import loginLogo from "../images/app-icon_BW.svg";
import { LoadingTag } from "../components/LoadingTag";
import { BsEyeSlash, BsEye } from "react-icons/bs";

function Login(props) {
  const apiEndpoint = `https://api.aaaagrup.com`;

  // Ui behavior
  const [blinkEye, setBlinkEye] = useState(false);
  const [passwdSee, setPasswdSee] = useState("password");

  const blinkeo = (initVal) => {
    if (initVal === true) {
      setBlinkEye(false);
      setPasswdSee("password");
    } else {
      setBlinkEye(true);
      setPasswdSee("text");
    }
  };

  let eyeIcon;
  if (blinkEye === true) {
    eyeIcon = (
      <i>
        <BsEyeSlash></BsEyeSlash>
      </i>
    );
  } else {
    eyeIcon = (
      <i>
        <BsEye></BsEye>
      </i>
    );
  }

  //Check Credentials
  const inputUsername = useRef();
  const inputPassword = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const submit = (e) => {
    e.preventDefault();

    let userInput = inputUsername.current.value.toLowerCase();
    let passwdInput = inputPassword.current.value;
    setIsLoading(true);

    const requestOptions = {
      method: "POST",
      header: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        username: userInput,
        passwd: passwdInput,
      }),
    };

    //Check if user length and password length has value
    if (userInput.length > 0 && passwdInput.length > 0) {
      fetch(`${apiEndpoint}?resource=auth`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.payload.credential === true) {
            setIsLoading(false);
            setError(null);

            let creds = {
              username: data.payload.username,
              token: data.payload.token,
              uid: data.payload.uid,
            };

            // Store credentials to sessionStorage
            sessionStorage.setItem("a4g", JSON.stringify(creds));
            navigate(`/list`);
          } else {
            setError({
              msg: "Usuario o clave equivocada",
            });
            setIsLoading(false);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <main className="container login">
        <div className="row align-items-center justify-content-center">
          <div className="row justify-content-center">
            <div className="col-6 text-center">
              <img src={loginLogo} alt="Logo del APP AAAAGRUP" />
              <h2>Ingresar</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <form
                className="needs-validation"
                name="login"
                id="login"
                onSubmit={submit}
              >
                <input type="hidden" name="formName" value="login" />
                <div className="mb-3">
                  <input
                    ref={inputUsername}
                    className="form-control"
                    type="text"
                    id="log_username"
                    placeholder="Tu usuario"
                    name="log_username"
                    required
                  />
                </div>
                <div className="mb-3 input-group">
                  <input
                    ref={inputPassword}
                    className="form-control"
                    type={passwdSee}
                    id="log_password"
                    placeholder="Tu clave"
                    name="log_password"
                    required
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-seepasswd"
                    onClick={() => blinkeo(blinkEye)}
                  >
                    {eyeIcon}
                  </button>
                </div>
                <div className="mb-3 d-grid">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    name="submit"
                    value="signin"
                  >
                    Ingresar
                  </button>
                  {error && <h2>{JSON.stringify(error.msg)}</h2>}
                  {isLoading && (
                    <React.Fragment>
                      <LoadingTag title={"Verificando..."}></LoadingTag>
                    </React.Fragment>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
export { Login };
