import React from "react";

function Sorteo__NumberItem(props) {
  return (
    <React.Fragment>
      <div className="item">
        <span className="item__number">
          {props.number}
          <span className="super">a</span>
        </span>
        <span className={`item__tiempos ${props.tiempos > 0 && "gotSold"}`}>
          {props.tiempos}
          <span className="super">t</span>
        </span>
      </div>
    </React.Fragment>
  );
}

export { Sorteo__NumberItem };
