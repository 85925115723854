import React from "react";
import "../sass/LoadingTag.scss";
import { BiLoaderCircle } from "react-icons/bi";

function LoadingTag(props) {
  return (
    <div className="loading-time container">
      <div className="row mt-2 mb-2">
        <div className="col-12 loading-time__assets">
          <BiLoaderCircle></BiLoaderCircle>
          <h2>{props.title ? props.title : `Cargando...`}</h2>
        </div>
      </div>
    </div>
  );
}
export { LoadingTag };
