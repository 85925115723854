import React from "react";

function Logout() {
  if (sessionStorage.getItem("a4g")) {
    sessionStorage.removeItem("a4g");
  }
  if (localStorage.getItem("a4g")) {
    localStorage.removeItem("a4g");
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Sesión cerrada exitosamente</h2>
          </div>
        </div>
        <div className="row">
          <a href="/login">Regresar al inicio</a>
        </div>
      </div>
    </React.Fragment>
  );
}

export { Logout };
