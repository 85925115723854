import React, { useRef, useState } from "react";
import { format } from "date-fns";
import "../sass/SorteoPicker.scss";
import { useNavigate } from "react-router-dom";

function getJsDateFormat(date) {
  return new Date(date.concat("Z").replace(/\s/, "T"));
}

function SorteoPicker(props) {
  // Format variables
  let sorteoReference = props.sourceLabel + "" + props.refNumber;
  let validToDate = format(getJsDateFormat(props.validTo), "dd/MM/yyyy");
  // let time = new Date(props.validTo);
  let time = getJsDateFormat(props.validTo);
  let validToTime = time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  let navigateToDashboard = useNavigate();
  const sorteoRow = useRef();
  const [picked, setPicked] = useState(false);

  const pickSorteo = () => {
    // console.log(sorteoRow.current.id);
    setPicked(true);

    let sortId = sorteoRow.current.id;
    async function addValueToLocalStorage(myData) {
      localStorage.setItem(
        "a4g",
        JSON.stringify({
          sorteo: sortId.replace("picker_", ""),
          costo: Number(props.sorteoCosto),
          ref: Number(props.refNumber),
        })
      );

      return true;
    }

    async function redirectTo() {
      await addValueToLocalStorage();

      navigateToDashboard("/sorteo");
    }

    //Navigato to the SingleSorteo Page if we store the value inside the localStorage
    redirectTo();
  };

  return (
    <div className="row sorteo pt-1 pb-1" onClick={pickSorteo}>
      <div className="col sorteo-item">
        <p className="sorteo-item__source">
          {props.source === 1 ? "Panamá" : "Costa Rica"}
        </p>
        <p className="sorteo-item__date">
          {`${validToDate}`}{" "}
          {props.itemActive < 3 ? (
            ""
          ) : (
            <span className="openOrClose">Cerrado</span>
          )}
        </p>
        <p className="sorteo-item__hour">{validToTime}</p>
      </div>
      <div className="col sorteo-data">
        <p className="sorteo-data__ref">{sorteoReference}</p>
        <p
          id={`picker_${props.sid}`}
          className="sorteo-data__picker"
          ref={sorteoRow}
        >
          {picked === true ? <i className="bi bi-check-circle-fill"></i> : ""}
        </p>
      </div>
    </div>
  );
}

export { SorteoPicker };
