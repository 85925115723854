import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaHandHoldingUsd, FaTicketAlt } from "react-icons/fa";

/**
 * Update the data inside the localStorage for the ticket selected
 * @param {*} newData
 * @returns
 */
function updateSorteoLocalStorage(newData) {
  let storeItem = "a4g";
  let dataHolder = [];
  dataHolder = JSON.parse(localStorage.getItem(storeItem));
  dataHolder.ticket = newData.ticket;

  localStorage.setItem(storeItem, JSON.stringify(dataHolder));
  return dataHolder;
}

function outputAllPrizesOfATicket(data) {
  let outputAmount = 0;
  // outputAmount = Number(
  //   data.tid in data.premiados ? data.premiados[data.tid][0].amount : 0
  // ).toFixed(2);
  if (data.tid in data.premiados) {
    data.premiados[data.tid].forEach((element) => {
      outputAmount += Number(element.amount);
    });
  }
  return outputAmount.toFixed(2);
}

function SorteoTransactionItem(props) {
  let navTo = useNavigate();
  let icon = {
    true: (
      <i>
        <FaHandHoldingUsd></FaHandHoldingUsd>
      </i>
    ),
    false: (
      <i>
        <FaTicketAlt></FaTicketAlt>
      </i>
    ),
  };

  // premiados
  let losPremiados = [];
  if (props.premiados != null) {
    losPremiados = props.premiados.records[0].tiempos.numerosPremiados;
    console.log(losPremiados, "props.Premiados!!!");
  }

  //Output depending on usage
  console.log(props.sales, "[SorteoTransactionItem] :: Sales!!!");
  if (props.sales === null || props.sales.length === 0) {
    return (
      <div className={`col-12 transaction border-bottom pb-2 mb-2`}>
        <div className="d-flex gap-1 px-0">Sin Registros</div>
      </div>
    );
  }

  // Filter first the output to just 5
  let salesToOutput = [];
  if (props.maxItems !== 0) {
    for (let index = 0; index < props.maxItems; index++) {
      salesToOutput.push(props.sales[index]);
    }
  } else {
    salesToOutput = props.sales;
  }
  return salesToOutput.map((item) => (
    <div
      className={`col-12 transaction ${
        item.tid in losPremiados && "winner"
      } border-bottom pb-2 mb-2`}
      key={item.tid}
    >
      <Link
        to={"/ticket"}
        onClick={(e) => {
          e.preventDefault();
          updateSorteoLocalStorage({ ticket: Number(item.tid) });
          navTo("/ticket");
        }}
      >
        <div className="d-flex gap-1 px-0">
          {item.tid in losPremiados ? icon.true : icon.false}
          <div className="col transaction__info d-flex justify-content-between">
            <div className="customer">
              <p className="customer__name">{item.acq.client_name}</p>
              <span className="customer__haveAnyPrize">
                $
                {outputAllPrizesOfATicket({
                  tid: item.tid,
                  premiados: losPremiados,
                })}
              </span>
            </div>
            <div className="tiempo">
              <p className="tiempo__ref">{item.ref}</p>
              <p className="tiempo__salesAmount">${item.acqAmount}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  ));
}

export { SorteoTransactionItem };
