import React, { useEffect, useState } from "react";
import { SideHeader } from "../components/SideHeader";
import { SorteoTransactionItem } from "../components/SorteoTransactionItem";
import { LoadingTag } from "../components/LoadingTag";

function SingleSorteoTransactions(props) {
  const localKey = `a4g`;

  const apiEndpoint = `https://api.aaaagrup.com`;
  const [salesData, setSalesData] = useState(null);
  const [sorteoInfo, setSorteoInfo] = useState(null);

  // Get the sorteoData store in Local
  const sorteoLocalData = JSON.parse(localStorage.getItem(localKey));
  const userLocalCredentials = JSON.parse(sessionStorage.getItem(localKey));

  useEffect(() => {
    fetch(
      `${apiEndpoint}/?resource=tiempos&rid=${sorteoLocalData.sorteo}&filters=true&where=sid&uid=${userLocalCredentials.uid}`
    )
      .then((response) => response.json())
      .then((data) => setSalesData(data));

    //Get sorteo info
    fetch(`${apiEndpoint}?resource=sorteos&rid=${sorteoLocalData.sorteo}`)
      .then((response) => response.json())
      .then((data) => setSorteoInfo(data));
  }, []);

  return (
    <React.Fragment>
      <SideHeader title="Transacciones"></SideHeader>
      <main className="container">
        <div className="row transactions mb-4">
          <div className="col-12 transactions-preview">
            <div className="row mb-5">
              {salesData && sorteoInfo ? (
                <SorteoTransactionItem
                  sales={salesData}
                  premiados={sorteoInfo}
                  maxItems={0}
                ></SorteoTransactionItem>
              ) : (
                <LoadingTag></LoadingTag>
              )}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
export { SingleSorteoTransactions };
