import React from "react";
import { Link } from "react-router-dom";
import "../sass/FooterNav.scss";
import {
  BsHouseDoorFill,
  BsCartPlus,
  BsListUl,
  BsFillFileEarmarkTextFill,
  BsPercent,
} from "react-icons/bs";

function FooterNav() {
  return (
    <footer className="site-footer">
      <div className="row fixed-bottom pt-2 pb-2">
        <nav className="nav">
          <Link to={"/list"} className={"nav-link active"}>
            <i>
              <BsHouseDoorFill></BsHouseDoorFill>
            </i>
          </Link>
          <Link
            className="nav-link"
            to={"/list"}
            aria-label={"Lista de Sorteos"}
          >
            <i>
              <BsListUl></BsListUl>
            </i>
          </Link>
          <Link className="nav-link sale" to={"/sale"} aria-label="Add Sale">
            <i>
              <BsCartPlus></BsCartPlus>
            </i>
          </Link>
          <Link
            className="nav-link"
            to={"/report"}
            aria-label="Reporte de Sorteo"
          >
            <i>
              <BsFillFileEarmarkTextFill></BsFillFileEarmarkTextFill>
            </i>
          </Link>
          <Link className="nav-link" to={"/sorteo"}>
            <i>
              <BsPercent></BsPercent>
            </i>
          </Link>
        </nav>
      </div>
    </footer>
  );
}
export { FooterNav };
