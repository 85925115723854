import React, { useState, useEffect } from "react";
import "../sass/Report.scss";
import { SideHeader } from "../components/SideHeader";
import { Sorteo__NumberItem } from "../components/Sorteo__NumberItem";
import { LoadingTag } from "../components/LoadingTag";

/**
 * This functions build the list of numbers sold and return also a listo of unique numbers + the amount of times it was bought
 * @param {*} data
 * @returns
 */
function getTiempos(data) {
  let tiempos = [];

  // Grab all sales from the data Tiempos data
  data.forEach((element) => {
    tiempos.push(...element.acquisition);
  });

  // Build the unique list
  let uniqueTiempos = [];
  tiempos.map((sale, index) => {
    //if number is already account then add to the existing number
    if (sale.number in uniqueTiempos) {
      uniqueTiempos[sale.number] = {
        number: Number(sale.number),
        tiempos:
          Number(uniqueTiempos[sale.number].tiempos) + Number(sale.tiempos),
        unique: false,
        list: `${uniqueTiempos[sale.number].list}, ${index}`,
      };
    } else {
      // Number its unique
      uniqueTiempos[sale.number] = {
        number: Number(sale.number),
        tiempos: Number(sale.tiempos),
        unique: true,
        list: index,
      };
    }
  });

  // Build totalQty by row
  let [total_0, total_1, total_2, total_3] = [0, 0, 0, 0];
  uniqueTiempos.forEach((unItem, index) => {
    if (index <= 25) total_0 += unItem.tiempos;
    if (index > 25 && index <= 50) total_1 += unItem.tiempos;
    if (index > 50 && index <= 75) total_2 += unItem.tiempos;
    if (index > 75 && index <= 100) total_3 += unItem.tiempos;
  });

  //Output the data
  return {
    raw: tiempos,
    unique: uniqueTiempos,
    totals: [total_0, total_1, total_2, total_3],
  };
}

/**
 * Build the number rows splited by 4 columns and each columns build it self in vertical mode
 * @returns
 */
function buildNumberRows() {
  const availableTens = Array.from(
    {
      length: 99,
    },
    (v, k) => k + 1
  );

  // Build each column of items
  let row_0 = [];
  let row_1 = [];
  let row_2 = [];
  let row_3 = [];
  availableTens.forEach((num) => {
    if (num <= 25) row_0.push(num);
    if (num > 25 && num <= 50) row_1.push(num);
    if (num > 50 && num <= 75) row_2.push(num);
    if (num > 75 && num <= 100) row_3.push(num);
  });

  return [row_0, row_1, row_2, row_3];
}

function Report() {
  const sorteoLocalData = JSON.parse(localStorage.getItem("a4g"));

  //Build the range of numbers to items
  const numRows = buildNumberRows();

  // Call the api to get all "tiempos" sold to the selected sorteo id
  const [listTiempos, setListTiempos] = useState(null);
  const [myUniques, setUniques] = useState(null);

  const apiEndpoint = `https://api.aaaagrup.com`;

  useEffect(() => {
    fetch(
      `${apiEndpoint}/?resource=tiempos&rid=${sorteoLocalData.sorteo}&switch=where`
    )
      .then((response) => response.json())
      .then((data) => {
        //Build List of numbers sold {uniques, and list}
        setUniques(getTiempos(data));
        setListTiempos(data);
      });
  }, []);

  if (listTiempos) {
    console.log(myUniques);
  }

  return (
    <React.Fragment>
      <SideHeader
        title={`Reporte ${sorteoLocalData.ref}`}
        destination={"/sorteo"}
      ></SideHeader>
      {listTiempos ? (
        <main className="container">
          <div className="row sorteo-reporte pt-1 pb-1">
            <div className="col sorteo-reporte--list">
              <div className="items-rows">
                <div className="col">
                  {numRows[0].map((num) => (
                    <Sorteo__NumberItem
                      key={num}
                      number={num}
                      tiempos={
                        num in myUniques.unique
                          ? myUniques.unique[num].tiempos
                          : 0
                      }
                    ></Sorteo__NumberItem>
                  ))}
                </div>
                <div className="col">
                  {numRows[1].map((num) => (
                    <Sorteo__NumberItem
                      key={num}
                      number={num}
                      tiempos={
                        num in myUniques.unique
                          ? myUniques.unique[num].tiempos
                          : 0
                      }
                    ></Sorteo__NumberItem>
                  ))}
                </div>
                <div className="col">
                  {numRows[2].map((num) => (
                    <Sorteo__NumberItem
                      key={num}
                      number={num}
                      tiempos={
                        num in myUniques.unique
                          ? myUniques.unique[num].tiempos
                          : 0
                      }
                    ></Sorteo__NumberItem>
                  ))}
                </div>
                <div className="col">
                  {numRows[3].map((num) => (
                    <Sorteo__NumberItem
                      key={num}
                      number={num}
                      tiempos={
                        num in myUniques.unique
                          ? myUniques.unique[num].tiempos
                          : 0
                      }
                    ></Sorteo__NumberItem>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row tiempos-summary fixed-bottom">
            <div className="col-12">
              <div className="tiempos-summary__list">
                <div className="tiempos-summary__total">
                  <span className="amount">{myUniques.totals[0]}</span>
                </div>
                <div className="tiempos-summary__total">
                  <span className="amount">{myUniques.totals[1]}</span>
                </div>
                <div className="tiempos-summary__total">
                  <span className="amount">{myUniques.totals[2]}</span>
                </div>
                <div className="tiempos-summary__total">
                  <span className="amount">{myUniques.totals[3]}</span>
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <LoadingTag></LoadingTag>
      )}
    </React.Fragment>
  );
}

export { Report };
