import React from "react";
import { Login } from "./pages/Login";
import { SorteosList } from "./pages/SorteosList";
import { SingleSorteo } from "./pages/SingleSorteo";
import { Sale } from "./pages/Sale";
import { SalePreview } from "./pages/SalePreview";
import { SaleSuccess } from "./pages/SaleSuccess";
import { SaleTicket } from "./pages/SaleTicket";
import { SingleSorteoTransactions } from "./pages/SingleSorteoTransactions";
import { Report } from "./pages/Report";
import { Logout } from "./pages/Logout";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login></Login>}></Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/list" element={<SorteosList></SorteosList>}></Route>
        <Route path="/sorteo" element={<SingleSorteo></SingleSorteo>}></Route>
        <Route path="/sale" element={<Sale></Sale>}></Route>
        <Route path="/report" element={<Report></Report>}></Route>
        <Route
          path="/transactions"
          element={<SingleSorteoTransactions></SingleSorteoTransactions>}
        ></Route>
        <Route path="/preview" element={<SalePreview></SalePreview>}></Route>
        <Route path="/success" element={<SaleSuccess></SaleSuccess>}></Route>
        <Route path="/ticket" element={<SaleTicket></SaleTicket>}></Route>
        <Route path="/logout" element={<Logout></Logout>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export { App };
