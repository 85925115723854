import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../sass/SalePreview.scss";
import { SalePreviewItemSold } from "../components/SalePreviewItemSold";
import { SalePreviewSummary } from "../components/SalePreviewSummary";
import { SideHeader } from "../components/SideHeader";

/**
 * Count the amount of tiempos in a given array
 * @param {*} inputData
 * @returns
 */
function countHowManyTimes(inputData) {
  let qty = Number(0);
  inputData.map((item) => {
    qty += Number(item.tiempos);
  });

  return Number(qty);
}

/**
 * Clean Up the date and Save it in localStorage
 * @param {*} data
 * @returns
 */
function saveNewData(data) {
  let outputData = [];

  //Clean Up the data
  data.map((item) => {
    if (item !== null) {
      outputData.push(item);
    }
  });

  // Save the new data
  localStorage.setItem("a4g_sale", JSON.stringify(outputData));

  //Return the output in case you wanted to display it
  return outputData;
}

function SalePreview() {
  let salesData = JSON.parse(localStorage.getItem("a4g_sale"));
  const [mySaleData, setMySaleSata] = useState(salesData);
  console.log(salesData);

  //Get Costo of this sorteo
  const sorteoData = JSON.parse(localStorage.getItem("a4g"));

  let cuantosTiempos = countHowManyTimes(mySaleData);
  let totalAmount = Number(cuantosTiempos) * sorteoData.costo;

  //Client name
  const clientName = useRef();

  // Navigate
  let navigate = useNavigate();

  //Submit or Complete BTN sale
  const makeSell = (e) => {
    if (window.confirm("¿Deseas realizar la venta?")) {
      console.log("Ok");
      console.log(`Cliente: ${clientName.current.value}`);
      console.log(salesData);

      // Build the data to be inserted
      const userSession = JSON.parse(sessionStorage.getItem("a4g"));
      const sorteoData = JSON.parse(localStorage.getItem("a4g"));

      const requestOptions = {
        method: "POST",
        header: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          uid: userSession.uid,
          sid: Number(sorteoData.sorteo),
          acq: {
            client_name: `${clientName.current.value}`,
          },
          acquisition: salesData,
          acqAmount: Number(totalAmount),
        }),
      };

      console.log(requestOptions, "RequestOptions");

      const apiEndpoint = `https://api.aaaagrup.com`;

      //Call the api and send the data
      fetch(`${apiEndpoint}?resource=tiempos`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // Clean localStorage Sale data
          localStorage.removeItem("a4g_sale");
          console.log(data, "apiResponse");

          // Store Recent ticket data
          let ticketData = {
            last_insertId: data.payload.last_insertId,
            ticket: data.payload.ticket,
          };

          localStorage.setItem("a4g_ticket", JSON.stringify(ticketData));
        })
        .then(() => {
          navigate("/success");
        });
    }
  };

  //Delete Btn
  let newDatatoSave;
  const deleteItem = (e) => {
    let targetRow = e.target.name;
    console.log(targetRow);

    targetRow = Number(targetRow.replace("delete_item_sale_", ""));
    console.log(`Id To delete: ${targetRow}`);

    // //Delete obj data by key & Update LocalStorage
    delete salesData[targetRow];
    newDatatoSave = saveNewData(salesData);
    setMySaleSata(newDatatoSave);
    console.log(newDatatoSave, "After Delete!!");
  };

  return (
    <React.Fragment>
      <SideHeader title="Revisar pedido" destination={"/sale"}></SideHeader>
      <main className="container sale-preview">
        <div className="row items-sold justify-content-end pb-1">
          <div className="col-12">
            {mySaleData.map((item, index) => (
              <SalePreviewItemSold
                key={index}
                itemId={index}
                tiempos={item.tiempos}
                numero={item.number}
                costo={sorteoData.costo}
                onClick={(e) => deleteItem(e)}
              ></SalePreviewItemSold>
            ))}
          </div>
        </div>
        <div className="row finish-bar pt-3 pb-3 px-2 fixed-bottom">
          <div className="col-12 mb-3">
            <SalePreviewSummary
              costoDelSorteo={sorteoData.costo}
              tiemposQty={cuantosTiempos}
              totalSale={totalAmount}
            ></SalePreviewSummary>
            <div className="row client-data">
              <div className="col-12">
                <hr></hr>
              </div>
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="clientName">
                    Cliente
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nombre del cliente"
                    aria-label="Client name"
                    aria-describedby="clientName"
                    name="client_name"
                    id="client_name"
                    ref={clientName}
                    onChange={(e) => {
                      console.log(clientName.current.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-grid">
            <button
              className="btn btn-success"
              type="button"
              onClick={(e) => {
                if (clientName.current.value !== "") {
                  makeSell(e);
                } else {
                  alert("Debes colocar el nombre del cliente");
                }
              }}
            >
              Completar
            </button>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
export { SalePreview };
