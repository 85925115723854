import React from "react";

function SaleSuccessNumberSold(props) {
  let outputNumberSold = 0;
  outputNumberSold =
    `${props.numberSold}`.length < 2
      ? `0${props.numberSold}`
      : props.numberSold;

  return (
    <div className="col number mb-2">
      <div className="number-item">
        <p className="tiempos">
          {props.tiemposSold}
          <sub>T</sub>
        </p>
        <p className="number">
          {outputNumberSold}
          <sub>a</sub>
        </p>
      </div>
    </div>
  );
}
export { SaleSuccessNumberSold };
